import uuid from "react-uuid"

/**
 * Gestion des différentes listes de mots
 * Le nombre de liste ajouté et leurs contenus
 */

const initialState = [
	{
		id: uuid(),
		words: [],
	},
	{
		id: uuid(),
		words: [],
	},
	{
		id: uuid(),
		words: [],
	},
]

const wordsListReducer = (state = initialState, action) => {
	switch (action.type) {
		case "WORDS_LIST_CREATE": {
			const id = uuid()
			return [...state, { id: id, words: "" }]
		}
		case "WORDS_LIST_DELETE": {
			const index = action.index
			const newState = state.filter((item) => item.id !== index)
			return newState
		}
		case "WORDS_LIST_ADD_WORDS": {
			const id = action.id
			const words = action.words.split("\n")
			
			/**On parcour avec map (qui renvoie une copie du tableau)
			 * Pour ne pas modifier directement le state on utilise : '...'
			 */
			const newState = state.map((item) => {
				if(item.id === id){
					return{
						...item,
						words: words
					}
				}
				return item
			})
			return newState
		} 
		default:
			return state
	}
}

export default wordsListReducer
