import { createTheme, makeStyles } from "@material-ui/core"
import { red, teal } from "@material-ui/core/colors"
import "fontsource-roboto"

/**Color */
const primary = teal
const secondary = red["A200"]

/**Breakpoint */
export const breakpoints = {xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920}

export const theme = createTheme({
    breakpoints:{
        values:{
            xs: breakpoints.xs,
            sm: breakpoints.sm,
            md: breakpoints.md,
            lg: breakpoints.lg,
            xl: breakpoints.xl,
        },
    },
	palette: {
		primary: primary,
		secondary: {
			main: secondary,
		},
	},
	typography: {
		fontFamily: ["Roboto", "Didot"],
	},
})

export const useStyles = makeStyles((theme) => ({
	titleInApp: {
		borderBottom: 1,
		borderBottomStyle: "solid",
		borderBottomColor: theme.palette.primary.main,
		marginBottom: 10,
	},
	resultButton: {
		backgroundColor: red["A400"],
		color: theme.palette.getContrastText(red["A400"]),
		fontWeight: "bold",
		"&:hover": {
			backgroundColor: red["A700"],
		},
	},
}))
