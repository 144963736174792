/**BONNE METHODE POUR L'AJOUT ET LA SUPPRESSION DU STATE. TOUJOURS CREER UN NOUVEAU STATE NE PAS MODIFIER LE STATE */

const intitialState =[
    /** Options disponibles
     * 0 : Default
     * 1 : + avant chaque mots
     * 2 : [ ] expression entre crochet
     */
    0 
]

const optionsReducer = (state = intitialState, action) => {
    switch(action.type){
        case "OPTIONS":{
            const linkType = action.linkType
            if(state.includes(linkType)){
                return state.filter((item) => item !== linkType)
            } else return [...state, linkType]
        }        
        default:
            return state
    }
}

export default optionsReducer