/** @jsxImportSource @emotion/react */
import { css  } from "@emotion/react"
import { Button, Grid, Card, CardContent, Typography } from "@material-ui/core"
import { Shuffle } from "@material-ui/icons"
import { useSelector, useDispatch } from "react-redux"
import { useStyles } from "../style/theme"

const Results = () => {
	const dispatch = useDispatch()
	const state = useSelector((state) => state)
	const classes = useStyles()

	//On désactive le boutton résultat si
	var desactiveValidation = true
	for (let index = 0; index < state.mixers.length; index++) {
		if (state.mixers[index].wordsListId.length < 1) {
			desactiveValidation = true
			break
		} else desactiveValidation = false
	}

	const onClickHandler = () => {
		state.mixers.forEach((mixer) => {
			dispatch({
				type: "MIXER_EXECUTE",
				id: mixer.id,
				result: mixing(state.wordsList, mixer, state.options),
			})
		})
	}
	return (
		<Grid container direction='column' spacing={2}>
			<Grid
				item
				css={css`
					text-align: center;
				`}
			>
				<Button
					variant="contained"
					className={classes.resultButton}
					onClick={onClickHandler}
					disabled={desactiveValidation}
				>
					<Shuffle />
					Lier les mots
				</Button>
			</Grid>
			<Grid>
				<Card
					variant="outlined"
					css={css`
						background-color: rgba(0, 0, 0, 0.09);
					`}
				>
					<Typography
						variant="h5"
						css={css`
							margin: 15px;
						`}
					>
						Résultat
					</Typography>
					<CardContent>
						{state.mixers.map((mixer, index) => (
							<ul
								key={index}
								css={css`
									list-style: none;
									padding: 0;
								`}
							>
								{mixer.result.map((option) =>
									option.map((line, index) => {
										return <li key={index}>{line}</li>
									})
								)}
							</ul>
						))}
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	)
}

/**
 * Fonction permettant le mixage des mots
 * @param {Tableau comprenant chaque liste de mots (tableau de mots)} wordsList
 * @param {Tableau comprenant les différents mixers} mixers
 * @param {Options pour le tri} options
 */
const mixing = (wordsList, mixer, options) => {
	/**Pour chaque listId on retourne la liste des mots */
	const lists = mixer.wordsListId.map((wordsListId) => {
		return wordsList.find((item) => item.id === wordsListId).words
	})
	let results = []

	/**Gestion des différentes options */
	for (let index = 0; index < options.length; index++) {
		const option = options[index]
		switch (option) {
			case 0:
				results.push(mixLists(lists))
				break
			case 1:
				let lists1 = lists.map((list) =>
					list.map((word) => {
						return "+" + word.replaceAll(" ", " +")
					})
				)
				results.push(mixLists(lists1))
				break
			case 2:
				let resultTemp = mixLists(lists)
				let result3 = resultTemp.map((result) => {
					return "[" + result + "]"
				})
				results.push(result3)
				break
			default:
				break;
		}
	}

	return results
}

const mixLists = (lists) => {
	let results = []
	/**On gère le cas ou il n'y a qu'une seule liste ou 0 */
	switch (lists.length) {
		case 1:
			console.log(lists[0])
			for (let index = 0; index < lists[0].length; index++) {
				results.push(lists[0][index])
			}
			console.log(results)
			break
		default:
			/**On initialise un tableau résultat une première fois avec les dernières colonnes*/
			results = addWords(lists[lists.length - 2], lists[lists.length - 1])

			/**Pour chaque autre colonnes on ajoute*/
			for (let j = lists.length - 3; j >= 0; j--) {
				results = addWords(lists[j], results)
			}
	}
	return results
}

/**
 * Permet d'ajouter des mots en amont d'une lite
 * @param {Liste de mots à ajouter} list
 * @param {Liste mots déjà mixée} previousWords
 */
const addWords = (list, previousWords) => {
	let results = []
	for (let word = 0; word < list.length; word++) {
		for (let index = 0; index < previousWords.length; index++) {
			results.push(list[word] + " " + previousWords[index])
		}
	}

	return results
}

export default Results
