import { createStore, applyMiddleware, combineReducers } from "redux";
import logger from "redux-logger"
import thunk from 'redux-thunk' //Permet de gérer les actions asynchrones (nécessaire pour axios)

import wordsList from './reducers/wordsListReducer'
import mixers from './reducers/mixersReducer'
import options from './reducers/optionsReducer'

const reducers = {wordsList, mixers, options}

const Store = process.env.NODE_ENV === "development" ? createStore(combineReducers(reducers), applyMiddleware(thunk, logger)) : createStore(combineReducers(reducers))

export default Store