import React from "react"
import { Grid, TextField, Button } from "@material-ui/core"
import { Clear } from "@material-ui/icons"
import { useDispatch, useSelector } from "react-redux"

const Words = ({ id, index }) => {
	const dispatch = useDispatch()
	const wordsList = useSelector((state) => state.wordsList)

	const onClickDelete = () => {
		dispatch({ type: "WORDS_LIST_DELETE", index: id })
		dispatch({ type: "MIXER_WORDS_LIST_DELETE", idWordsList: id })
	}
	return (
		<Grid container spacing={1} direction="column" alignItems="center">
			<Grid item>
				<TextField
					label={"Liste " + (index + 1)}
					variant="filled"
					multiline
					rows={12}
					onChange={(e) =>
						dispatch({
							type: "WORDS_LIST_ADD_WORDS",
							words: e.target.value,
							id: id,
						})
					}
					value={wordsList[index].words.toString().replaceAll(",", '\n')}
				/>
			</Grid>
			<Grid item>
				<Button
					variant="contained"
					color="secondary"
					onClick={onClickDelete}
					disabled={wordsList.length <= 1 ? true : false}
				>
					<Clear />
					Supprimer la liste
				</Button>
			</Grid>
		</Grid>
	)
}

export default Words
