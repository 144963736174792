/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { AppBar, Grid, Paper, Toolbar, Typography } from "@material-ui/core"

import { ThemeProvider } from "@material-ui/core/styles"
import { theme, breakpoints } from "./style/theme"

import WordsList from "./components/wordsList"
import Mixers from "./components/mixers"
import Options from "./components/options"
import Results from "./components/results"

function AppLoren() {
	return (
		<ThemeProvider theme={theme}>
			<AppBar position="static" color="primary">
				<Toolbar>
					<Grid
						container
						justifyContent="space-between"
						alignItems="center"
						css={css`
							@media (max-width: ${breakpoints.md + "px"}) {
								text-align: center;
							}
						`}
					>
						<Grid item xs={12} md={5}>
							<Typography variant="h3">MixLoren</Typography>
						</Grid>
						<Grid item xs={12} md={5}>
							<Typography
								variant="body2"
								css={css`
									font-style: italic;
									@media (min-width: ${breakpoints.md + "px"}) {
										text-align: justify;
										max-width: 500px;
									}
								`}
							>
								Application de mixeurs de mots clés afin de faciliter la mise en
								place de campagnes avec Google Analytics.
							</Typography>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Grid
				container
				justifyContent="center"
				css={css`
					margin-top: 20px;
				`}
			>
				<Grid
					item
					xs={11}
					md={8}
					css={css`
						margin-bottom: 20px;
					`}
				>
					<Paper>
						<Grid
							container
							css={css`
								padding: 20px;
							`}
						>
							<WordsList />
							<Mixers />
							<Options />
							<Results />
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}

export default AppLoren
