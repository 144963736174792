/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import {
	FormGroup,
	FormControlLabel,
	Checkbox,
	Grid,
	Button,
	Typography,
	useTheme,
} from "@material-ui/core"
import { Clear } from "@material-ui/icons"
import { useSelector, useDispatch } from "react-redux"

const Mixer = ({ id, index }) => {
	const wordsList = useSelector((state) => state.wordsList)
	const mixers = useSelector((state) => state.mixers)
	const dispatch = useDispatch()
	const theme = useTheme()
	return (
		<Grid container spacing={2} alignItems="center" justifyContent="center">
			<Grid item>
				<Typography
					variant="h5"
					css={css`
						color: 1px solid ${theme.palette.secondary.main};
						padding: 5px;
					`}
				>
					Mixeur {index + 1}
				</Typography>
			</Grid>
			{wordsList.map((item, index) => (
				<Grid item key={index}>
					<FormGroup row>
						<FormControlLabel
							control={
								<Checkbox
									onChange={() =>
										dispatch({
											type: "MIXER_WORDS_LIST_CHECKED",
											index: id,
											idWordsList: item.id,
										})
									}
								/>
							}
							label={"Liste " + (index + 1)}
						/>
					</FormGroup>
				</Grid>
			))}
			<Grid item>
				<Button
					variant="contained"
					color="secondary"
					onClick={() => dispatch({ type: "MIXER_DELETE", index: id })}
					disabled={mixers.length <= 1}
				>
					<Clear />
				</Button>
			</Grid>
		</Grid>
	)
}

export default Mixer
