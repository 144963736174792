/** @jsxImportSource @emotion/react */
import {css } from '@emotion/react'
import { useSelector, useDispatch } from "react-redux"
import {Grid, Button, Typography } from "@material-ui/core"
import { Add } from "@material-ui/icons"
import {useStyles} from '../style/theme'

import Words from "./words"


const WordsList = () => {
	const wordsList = useSelector((state) => state.wordsList)
	const dispatch = useDispatch()
	const classes = useStyles();
	return (
		<Grid item xs={12} css={css`margin-bottom: 20px;`}>
			<Typography variant="h3" className={classes.titleInApp}>Mots</Typography>
			<Grid container spacing={2} justifyContent="center">
				<Grid item xs={12} css={css`text-align: center;`}>
					<Button
						variant="contained"
						color="primary"
						onClick={() => dispatch({ type: "WORDS_LIST_CREATE" })}
						disabled={wordsList.length >= 6 ? true : false}
					>
						<Add />
						Ajouter une liste
					</Button>
				</Grid>
				{wordsList.map((item, index) => (
					<Grid item key={index}>
						<Words id={item.id} index={index} />
					</Grid>
				))}
			</Grid>
		</Grid>
	)
}

export default WordsList
