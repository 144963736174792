import uuid from "react-uuid"

/**essayer avec les maps (new Maps()) avec comme ID l'uuid ca sera surement plus clair*/
const initialState = [
	{
		id: uuid(),
		wordsListId: [],
		result: []
	},
]

const mixersReducer = (state = initialState, action) => {
	switch (action.type) {
		case "MIXER_CREATE": {
			const id = uuid()
			return [...state, { id: id, wordsListId: [], result:[] }]
		}
		case "MIXER_DELETE": {
			const id = action.index
			return state.filter((item) => item.id !== id)
		}
		case "MIXER_WORDS_LIST_CHECKED": {
			const index = action.index
			const idWordsList = action.idWordsList
			/**On s'assure de l'id du mixer
			 * Puis on ajoute l'id de la list de mots si c'est checked ou on le supprime si déjà checked
			 */

			const newState = state.map((item) => {
				if (item.id === index) {
					if (item.wordsListId.includes(idWordsList)) {
						return {
							...item,
							wordsListId: item.wordsListId.filter(
								(item) => item !== idWordsList
							),
						}
					} else {
						return {
							...item,
							wordsListId: [...item.wordsListId, idWordsList],
						}
					}
				}
				/**() --> quand on n'utilise pas return
				 * {} --> quand on utilise return
				 */
				return item
			})
			return newState
		}
		case "MIXER_WORDS_LIST_DELETE": {
			const idWordsList = action.idWordsList
			const newState = state.map((item) => {
				return {
					...item,
					wordsListId: item.wordsListId.filter((item) => item !== idWordsList),
				}
			})
			return newState
		}
		case "MIXER_EXECUTE":{
			const id = action.id
			const result = action.result
			const newState = state.map((item) => {
				if(item.id === id){
					return {
						...item,
						result: result
					}
				}
				return item
			})
			return newState
		}
		default:
			return state
	}
}

export default mixersReducer
