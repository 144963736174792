import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { CssBaseline } from '@material-ui/core'

import App from './App'
import store from './redux/store'

/**
 * CSS BASELINE --> Supprime le style par défault des navigateurs
 * PROVIDER --> Permet d'accéder au store (donc aux données)
 * BROWSER ROUTER --> Pour gérer les ancres. (If we want to handle the dynamic request then use BrowserRouter and if we want to serve the static request then use HashRouter)
 */

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<CssBaseline />
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
)
