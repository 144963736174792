/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import { Grid, Button, Typography } from "@material-ui/core"
import { Add } from "@material-ui/icons"
import { useSelector, useDispatch } from "react-redux"

import Mixer from "./mixer"
import { useStyles } from "../style/theme"

const Mixers = () => {
	const mixers = useSelector((state) => state.mixers)
	const dispatch = useDispatch()
	const classes = useStyles()
	return (
		<Grid item xs={12} css={css`margin-bottom: 20px;`}>
			<Typography variant="h3" className={classes.titleInApp}>Mixeurs</Typography>

			<Grid container direction="column" component="section" alignItems="center">
				{mixers.map((item, index) => (
					<Grid item key={index}>
						<Mixer id={item.id} index={index} />
					</Grid>
				))}
				<Grid item css={css`margin-top: 10px;`}>
					<Button
						variant="contained"
						color="primary"
						onClick={() => dispatch({ type: "MIXER_CREATE" })}
					>
						<Add />
						Ajouter un Mixer
					</Button>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Mixers
