import React from "react"
import {
	FormGroup,
	Grid,
	FormControlLabel,
	Checkbox,
	Typography,
} from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"

import { useStyles } from "../style/theme"

const Options = () => {
	const dispatch = useDispatch()
	const options = useSelector((state) => state.options)
	const classes = useStyles()

	return (
		<Grid item xs={12}>
			<Typography variant="h3" className={classes.titleInApp}>
				Options
			</Typography>
			<Grid container justifyContent="center">
				<Grid item>
					<FormGroup row>
						<FormControlLabel
							control={
								<Checkbox
									checked={options.includes(0) }
									onChange={() => dispatch({ type: "OPTIONS", linkType: 0 })}
								/>
							}
							label="Défaut"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={options.includes(1)}
									onChange={() => dispatch({ type: "OPTIONS", linkType: 1 })}
									
								/>
							}
							label='"+" avant chaque mots'
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={options.includes(2)}
									onChange={() => dispatch({ type: "OPTIONS", linkType: 2 })}
									
								/>
							}
							label='Expression entre crochet "[]"'
						/>
					</FormGroup>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Options
